<script>
import axios from 'axios';

export default {
  data() {
    return {
      data: {
      }
    }
  },
  props: {
  },
  components: {
  },
  methods: {
    loadingData() {
      axios.get('/api/account/dashboard', { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
  },
  computed: {
    getProfileLogin: function() {
      return localStorage.getItem('user.login');
    },
  }
};
</script>

<template>
  <h3>Welcome, {{ getProfileLogin }}!</h3>
</template>