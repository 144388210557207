<script>
import axios from 'axios';

import EpisodeRowItem from "../../episodes/EpisodeRowItem.vue";

export default {
  data() {
    return {
      data: {
      }
    }
  },
  props: {
  },
  components: {
    EpisodeRowItem
  },
  methods: {
    loadingData() {
      axios.get('/api/account/favourites/episodes', { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
  }
};
</script>

<template>  
  <div>
    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
      <h4 class="mb-sm-0">Favourite episodes</h4>
    </div>
    <div class="team-list list-view-filter row" v-if="data.episodes && data.episodes.length > 0">
      <div v-for="p in data.episodes" v-bind:key="p">
        <EpisodeRowItem v-bind:data="p"></EpisodeRowItem>
      </div>
    </div>
  </div>
</template>