<script>
export default {
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          link: '',
          title: '',
          memo: '',
          urlAvatar: '',
          urlCover: ''
        }
      }
    }
  },
  components: {
  },
  computed: {
  }
};
</script>

<template>
  <div class="card team-box ribbon-box ribbon-fill">
    <div class="card-body px-4">
      <div class="ribbon ribbon-danger" v-if="data.flags.isNew"><small>New</small></div>
      <div class="row align-items-center team-row">
        <div class="col team-settings">
          <div class="row align-items-center">
            <div class="col" v-if="false">
              <div class="flex-shrink-0 me-2">
                <button type="button" class="btn fs-16 p-0 favourite-btn"><i class="ri-star-fill"></i></button>
              </div>
            </div>
            <div class="col text-end dropdown">
              <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false"><i class="ri-more-fill fs-17"></i></a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <router-link :to="{ name: 'profile', params: { login: data.login } }" class="dropdown-item">
                    <i class="ri-eye-fill text-muted me-2 align-bottom"></i> View
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col">
          <div class="team-profile-img">
            <div class="img-thumbnail rounded-circle">
              <router-link :to="{ name: 'profile', params: { login: data.login } }">
                <div class="avatar-lg flex-shrink-0" v-if="!data.urlAvatar">
                  <div class="avatar-title rounded-circle">{{ data.login[0].toUpperCase() }}</div>
                </div>
                <img v-bind:src="data.urlAvatar" alt="" class="avatar-lg img-fluid d-block rounded-circle" v-if="data.urlAvatar">
              </router-link>
            </div>
            <div class="team-content">
              <router-link :to="{ name: 'profile', params: { login: data.login } }" class="d-block">
                <h5 class="fs-16 mb-1">{{ data.login }}</h5>
              </router-link>
              <p class="text-muted mb-0">{{ data.displayName }}</p>
              <p class="text-muted mb-0"><i class="ri-calendar-2-line"></i> {{ new Date(data.createdAt).toLocaleString() }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col">
          <div class="row text-muted text-center">
            <div class="col-6">
              <h5 class="mb-1">{{ data.stat.podcasts }}</h5>
              <p class="text-muted mb-0">Podcasts</p>
            </div>
            <div class="col-6 border-end border-end-dashed">
              <h5 class="mb-1">{{ data.stat.episodes }}</h5>
              <p class="text-muted mb-0">Episodes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>