<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import Container from "@/components/Container.vue";
import appConfig from "../../../../app.config";

import FavouritesSidebar from "./FavouritesSidebar.vue";
import FavouritesMenuMain from "./FavouritesMenuMain.vue";
import FavouritesMenuEpisodes from "./FavouritesMenuEpisodes.vue";
import FavouritesMenuPodcasts from "./FavouritesMenuPodcasts.vue";
import FavouritesMenuProfiles from "./FavouritesMenuProfiles.vue";
import FavouritesMenuPosts from "./FavouritesMenuPosts.vue";

export default {
  page: {
    title: "Favourites",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Favourites",
      menu: 'main',
    };
  },
  components: {
    Layout,
    PageHeader,
    Container,
    FavouritesSidebar,
    FavouritesMenuMain,
    FavouritesMenuEpisodes,
    FavouritesMenuPodcasts,
    FavouritesMenuProfiles,
    FavouritesMenuPosts,
  },
  created: function () {
  },
  mounted() {
    this.$refs.mainPanel.loadingData();
  },
  methods: {
    sidebarMenuChanged: function(e) {
      this.menu = e;
      this.$nextTick(() => {
        switch (this.menu) {
          case 'podcasts':
            this.$refs.podcastsPanel.loadingData();
            break;
          case 'episodes':
            this.$refs.episodesPanel.loadingData();
            break;
          case 'profiles':
            this.$refs.profilesPanel.loadingData();
            break;
          case 'posts':
            this.$refs.postsPanel.loadingData();
            break;
          case 'main':
          default:
            this.$refs.mainPanel.loadingData();
            break;
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" v-if="false" />

    <Container>
      <template #sidebar>
        <FavouritesSidebar @changed-sidebar-menu="sidebarMenuChanged"></FavouritesSidebar>
      </template>
      <template #content>
        <FavouritesMenuMain ref="mainPanel" v-if="menu==='main'"></FavouritesMenuMain>
        <FavouritesMenuPodcasts ref="podcastsPanel" v-if="menu==='podcasts'"></FavouritesMenuPodcasts>
        <FavouritesMenuEpisodes ref="episodesPanel" v-if="menu==='episodes'"></FavouritesMenuEpisodes>
        <FavouritesMenuProfiles ref="profilesPanel" v-if="menu==='profiles'"></FavouritesMenuProfiles>
        <FavouritesMenuPosts ref="postsPanel" v-if="menu==='posts'"></FavouritesMenuPosts>
      </template>
    </Container>
  </Layout>
</template>
