<script>
import ProfileItem from "../../profiles/ProfileItem.vue";

export default {
  data() {
    return {
      menu: 'main'
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    ProfileItem,
  },
  methods: {
    selectAccountMenu(item) {
      this.menu = item;
      this.$emit('changedSidebarMenu', item);
    },
  }
};
</script>

<template>
  <div class="team-list grid-view-filter row">

    <div class="col-12">
      <ProfileItem v-bind:data="data.profile" v-if="data.profile"></ProfileItem>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-0">Favourites</h5>
        </div>
        <b-list-group class="list-group-fill-primary list-group-flush">
          <b-list-group-item :active="menu === 'main'" v-on:click="selectAccountMenu('main')" class="list-group-item-action" role="button">
            <i class="ri-dashboard-line align-middle me-2"></i> Home
          </b-list-group-item>
          <b-list-group-item :active="menu === 'podcasts'" v-on:click="selectAccountMenu('podcasts')" class="list-group-item-action" role="button">
            <i class="ri-folder-music-line align-middle me-2"></i> Podcasts
          </b-list-group-item>
          <b-list-group-item :active="menu === 'episodes'" v-on:click="selectAccountMenu('episodes')" class="list-group-item-action" role="button">
            <i class="ri-music-line align-middle me-2"></i> Episodes
          </b-list-group-item>
          <b-list-group-item :active="menu === 'profiles'" v-on:click="selectAccountMenu('profiles')" class="list-group-item-action" role="button">
            <i class="ri-user-line align-middle me-2"></i> Profiles
          </b-list-group-item>
          <b-list-group-item :active="menu === 'posts'" v-on:click="selectAccountMenu('posts')" class="list-group-item-action" role="button">
            <i class="ri-message-line align-middle me-2"></i> Posts
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

  </div>
</template>